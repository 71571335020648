import { ServicoParagraph } from "../../components/Servico/ServicoStyled";

export interface AboutUsInterface {
  title: string;
  description: () => React.ReactElement;
}

export const AboutUsEN: AboutUsInterface = {
  title: "About us",
  description: () => (
    <>
      <ServicoParagraph className={"my-2"}>
        Introducing servi.co, a dedicated platform crafted to seamlessly connect
        individuals and businesses with skilled service providers across a wide
        range of industries. Our platform is designed with one core objective in
        mind: to simplify the search for professional services, offering an
        unparalleled combination of quality, reliability, and competitive
        pricing. We believe that finding the right expert should never be
        complicated, and we’re here to make that process as effortless as
        possible.
      </ServicoParagraph>

      <ServicoParagraph>
        Our commitment at servi.co is to provide users with access to a broad
        network of professionals – whether it’s for cleaning, painting or
        plumbing work, beauty services, and much more. Our platform curates a
        wide selection of trusted providers, enabling users to quickly and
        confidently choose the right expert for their specific needs. Every
        professional in our network is vetted to ensure high standards of
        service, because we believe in delivering nothing less than the best.
      </ServicoParagraph>

      <ServicoParagraph>
        Our mission is clear: to bring structure and ease to the often chaotic
        and fragmented service industry. We strive to offer a secure,
        user-friendly environment where users can effortlessly discover and
        engage with service providers, free from the usual frustrations and
        uncertainties. Acknowledging the common challenges people face when
        seeking qualified professionals, we aim to turn what can be a stressful
        process into a smooth and positive experience.
      </ServicoParagraph>

      <ServicoParagraph>
        Guided by the core values of transparency, quality, speed, and fair
        pricing, servi.co works to elevate the experience of every user. From
        the moment a task is posted, through easy and intuitive navigation, to
        smooth communication with potential service providers, we streamline
        each step to make finding and hiring professionals quick and efficient.
        Users can rest assured knowing they are choosing from a pool of experts
        at competitive rates, with no hidden fees and a strong emphasis on
        fairness and clarity.
      </ServicoParagraph>

      <ServicoParagraph>
        Moreover, servi.co doesn’t just help users find services—it’s a platform
        designed to support the growth of small and medium-sized businesses. By
        offering a trusted marketplace, we help service providers showcase their
        skills, build their reputation, and expand their client base. We are
        passionate about creating a space where businesses thrive, and where
        clients always receive the highest quality service at the best possible
        price.
      </ServicoParagraph>

      <ServicoParagraph>
        Join us today and experience the new, smarter way to connect with
        professionals. At servi.co, we’re here to help you not only find the
        service you need but to make every interaction a step towards greater
        success.
      </ServicoParagraph>
    </>
  ),
};

export const AboutUsDE: AboutUsInterface = {
  title: "Über uns",
  description: () => (
    <>
      <ServicoParagraph className={"my-2"}>
        Die Plattform servi.co verbindet Privatpersonen und Unternehmen nahtlos
        mit qualifizierten Dienstleister:innen aus einer Vielzahl von Branchen. Unser
        Hauptziel ist es, die Suche nach professionellen Dienstleistungen zu
        vereinfachen und dabei eine unvergleichliche Kombination aus Qualität,
        Zuverlässigkeit und wettbewerbsfähigen Preisen zu gewährleisten. Wir sind der
        Überzeugung, dass die Suche nach den richtigen Expert:innen 
        niemals kompliziert sein sollte. Deshalb sind wir hier, 
        um diesen Prozess so einfach wie möglich zu gestalten.
      </ServicoParagraph>
      <ServicoParagraph>
        Unser Engagement bei servi.co liegt darin, Nutzer:innen den Zugang zu einem
        breiten Netzwerk von Fachleuten zu ermöglichen – sei es für Reinigungsarbeiten,
        Malerarbeiten oder Klempnerarbeiten, Schönheitsdienstleistungen und vieles
        mehr. Die Plattform bietet eine sorgfältige Auswahl vertrauenswürdiger Dienstleister:innen, 
        sodass Nutzer:innen schnell und sicher die
        passenden Expert:innen für ihre individuellen Bedürfnisse finden können.
      </ServicoParagraph>

      <ServicoParagraph>
        Mit dem klaren Auftrag, Ordnung und Einfachheit in die oft chaotische
        und zersplitterte Dienstleistungsbranche zu bringen, bietet servi.co 
        eine sichere und benutzerfreundliche Umgebung. Nutzer:innen können
        mühelos Dienstleister:innen finden und mit ihnen zusammenarbeiten, frei von
        den üblichen Frustrationen und Unsicherheiten. Da die Suche nach
        qualifizierten Fachkräften oft herausfordernd ist, möchten wir diesen
        Prozess für unsere Nutzer:innen in eine positive und reibungslose Erfahrung
        verwandeln.
      </ServicoParagraph>

      <ServicoParagraph>
        Geleitet von den Werten Transparenz, Qualität, Effizienz und fairen
        Preisen, verbessert servi.co das Erlebnis jedes Nutzers und jeder Nutzerin.
        Vom Moment, in dem ein Auftrag gepostet wird, über die einfache und intuitive
        Navigation, bis hin zur reibungslosen Kommunikation mit potenziellen
        Dienstleister:innen, wird jeder Schritt optimiert. Dies gewährleistet, dass
        die Suche und Auswahl der passenden Expert:innen schnell und effizient
        verläuft. Nutzer:innen profitieren dabei von einem Pool an Fachkräften zu
        wettbewerbsfähigen Preisen – ohne versteckte Kosten und mit einem klaren
        Fokus auf Fairness und Transparenz.
      </ServicoParagraph>

      <ServicoParagraph>
        Die Plattform unterstützt nicht nur Nutzer:innen bei der Suche nach
        Dienstleistungen, sondern fördert auch das Wachstum von kleinen und
        mittelständischen Unternehmen. Durch den vertrauenswürdigen Marktplatz
        können Dienstleister:innen ihre Fähigkeiten präsentieren, ihren Ruf aufbauen
        und ihren Kundenstamm erweitern. Ziel ist es, einen Raum zu schaffen, in
        dem Unternehmen gedeihen und Kund:innen stets qualitativ hochwertige
        Dienstleistungen zu den bestmöglichen Preisen erhalten.
      </ServicoParagraph>

      <ServicoParagraph>
        Melden Sie sich noch heute an und erleben Sie den neuen, intelligenten
        Weg, um mit Fachleuten in Kontakt zu treten. Mit servi.co wird jede
        Interaktion ein Schritt in Richtung größerer geschäftlicher Erfolge.
      </ServicoParagraph>
    </>
  ),
};
