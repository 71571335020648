import styled from "styled-components";

const DynamicParagraph = styled.p`
  padding: 10px;
  color: ${(props) => props.theme.mainColor};
  font-size: 1.3rem;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

export const HomeResourcesEN: IHomeResources = {
  title: () => (
    <div style={{ border: "5px solid #68CF44" }}>
      <DynamicParagraph>
        <b>
          Welcome to servi.co, a platform connecting service providers with
          individuals and businesses. Learn more about our values on our About
          Us page.
          <br />
          We’re inviting service providers to join servi.co. During our
          promotional period, new members receive free, no-obligation membership
          until 31.12.2025. Afterward, you can choose to continue. The promotion
          ends on 31.03.2025.
          <br />
          By signing up, you'll get full access to the platform and a pricing
          overview for after your free membership ends. This is a great
          opportunity to grow your business. With servi.co, you’ll benefit from
          a user-friendly platform, transparent pricing, and support for small
          and medium-sized businesses to thrive while offering clients quality
          services at competitive prices.
          <br />
          Join us today and start enhancing your business success.
          <br />
          Best of luck!
        </b>
      </DynamicParagraph>
    </div>
  ),
  latestTitle: "Latest tasks",
  mostPopular: "Most popular tasks",
  messageBtn: "Open",
};

export const HomeResourcesDE: IHomeResources = {
  title: () => (
    <div style={{ border: "5px solid #68CF44" }}>
      <DynamicParagraph>
        <b>
          Willkommen bei servi.co, einer Plattform, die Dienstleister:innen mit
          Privatpersonen und Unternehmen verbindet. Mehr über unsere Werte
          erfahren Sie auf unserer Über-uns-Seite.
          <br />
          Wir laden Dienstleister:innen ein, sich bei servi.co anzumelden. Während
          unserer Werbeaktion erhalten neue Mitglieder eine kostenlose,
          unverbindliche Mitgliedschaft bis zum 31.12.2025. Danach können Sie
          entscheiden, ob Sie weitermachen möchten. Die Aktion endet am
          31.03.2025.
          <br />
          Mit Ihrer Anmeldung erhalten Sie vollen Zugriff auf die Plattform
          sowie eine Preisinformation für die Zeit nach Ablauf der kostenlosen
          Mitgliedschaft. Dies ist eine großartige Gelegenheit, Ihr Geschäft
          auszubauen. Mit servi.co profitieren Sie von einer
          benutzerfreundlichen Plattform, transparenten Preisen und
          Unterstützung für kleine und mittelständische Unternehmen, während
          Kunden Zugang zu hochwertigen Dienstleistungen zu wettbewerbsfähigen
          Preisen erhalten.
          <br />
          Melden Sie sich noch heute an und beginnen Sie, Ihren geschäftlichen
          Erfolg zu steigern.
          <br />
          Viel Erfolg!
        </b>
      </DynamicParagraph>
    </div>
  ),
  messageBtn: "Öffnen",
  latestTitle: "Aufträge",
  mostPopular: "Beliebtesten Aufträge",
};

export interface IHomeResources {
  title: () => React.ReactElement;
  messageBtn: string;
  latestTitle: string;
  mostPopular: string;
}
