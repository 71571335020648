export const ProfileResourcesEN: IProfileResources = {
  title: "My Profile",
  companyTitle: "Company: ",
  errors: {
    notFound: "The requested profile could not been found.",
  },
  sendMessage: {
    button: "Contact user",
    label: "Message",
    placeholder: "Message text",
    error: "Please enter a valid message.",
    name: "message",
    button2: "Send",
  },
  modal: {
    title: "Task created.",
    text: "",
    button: "Done",
  },
  contactUs: {
    email: {
      label: "Message title",
      placeholder: "Message title",
      error: "Please enter a valid title.",
      name: "title",
      validation: "The entered E-Mail address is not valid.",
    },
    message: {
      label: "Message",
      placeholder: "Message text",
      error: "Please enter a valid message.",
      name: "message",
    },
    contact: "Email us: contact@service.co",
    button: "Send",
  },
};

export const ProfileResourcesDE: IProfileResources = {
  title: "Mein Profil",
  companyTitle: "Unternehmen: ",
  errors: {
    notFound: "Das angeforderte Profil konnte nicht gefunden werden.",
  },
  sendMessage: {
    button: "Kontaktieren Sie die Dienstleister:in",
    label: "Nachricht",
    placeholder: "Nachrichtentext",
    error: "Bitte geben Sie eine gültige Nachricht ein.",
    name: "message",
    button2: "Schicken",
  },
  modal: {
    title: "Auftrag erstellt.",
    text: "",
    button: "Erledigt",
  },
  contactUs: {
    email: {
      label: "Nachrichtentitel",
      placeholder: "Nachrichtentitel",
      error: "Bitte geben Sie einen gültigen Titel ein.",
      name: "title",
      validation: "Die eingegebene E-Mail-Adresse ist ungültig.",
    },
    message: {
      label: "Nachricht",
      placeholder: "Nachrichtentext",
      error: "Bitte geben Sie eine gültige Nachricht ein.",
      name: "message",
    },
    contact: "Schreiben Sie uns eine E-Mail: contact@servi.co",
    button: "Schicken",
  },
};
interface ErrorMessages {
  notFound: string;
}

interface SendMessage {
  button: string;
  label: string;
  placeholder: string;
  error: string;
  name: string;
  button2: string;
}

interface Modal {
  title: string;
  text: string;
  button: string;
}

interface ContactField {
  label: string;
  placeholder: string;
  error: string;
  name: string;
  validation?: string;
}

interface ContactUs {
  email: ContactField;
  message: ContactField;
  contact: string;
  button: string;
}

export interface IProfileResources {
  title: string;
  companyTitle: string;
  errors: ErrorMessages;
  sendMessage: SendMessage;
  modal: Modal;
  contactUs: ContactUs;
}
