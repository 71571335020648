import { EnumOfferCategory } from "../../../redux/interfaces";

export const CategoriesResourcesEN: CategoriesResourcesInterface = {
  categories: [
    {
      label: "HOUSEHOLD AND OFFICE HELP",
      options: [
        {
          value: EnumOfferCategory.HouseCleaning,
          label: "House and Apartment Cleaning",
        },
        { value: EnumOfferCategory.OfficeCleaning, label: "Office Cleaning" },
        { value: EnumOfferCategory.Ironing, label: "Ironing" },
        {
          value: EnumOfferCategory.HouseOfficeHelpOther,
          label: "Other House and Office Help",
        },
      ],
    },
    {
      label: "HOUSEHOLD WORKS",
      options: [
        {
          value: EnumOfferCategory.Painting,
          label: "Paintings and Wall Works",
        },
        {
          value: EnumOfferCategory.ElectricalService,
          label: "Electrical Service",
        },
        {
          value: EnumOfferCategory.ElectricalAppliances,
          label: "Electrical Appliances Repair",
        },
        {
          value: EnumOfferCategory.FittingAssemblingDissembling,
          label: "Fitting – Assembling - Dissembling",
        },
        {
          value: EnumOfferCategory.BathroomSanitary,
          label: "Bathroom and Sanitary",
        },
        { value: EnumOfferCategory.Tiling, label: "Tilling" },
        { value: EnumOfferCategory.Plumbing, label: "Plumbing Service" },
        { value: EnumOfferCategory.FlooringWork, label: "Flooring Work" },
        {
          value: EnumOfferCategory.HouseWorksOther,
          label: "Other Home Works",
        },
      ],
    },
    {
      label: "RENOVATION",
      options: [
        {
          value: EnumOfferCategory.HouseRenovation,
          label: "House and Apartment Renovation",
        },
        {
          value: EnumOfferCategory.OfficeRenovation,
          label: "Office Renovation",
        },
        {
          value: EnumOfferCategory.RenovationOther,
          label: "Other Object Renovation",
        },
      ],
    },
    {
      label: "GARDEN AND LANDSCAPE DESIGN",
      options: [
        { value: EnumOfferCategory.Gardening, label: "Gardening" },
        {
          value: EnumOfferCategory.FenceConstruction,
          label: "Fence Construction",
        },
        {
          value: EnumOfferCategory.GardenRenovation,
          label: "Garden Renovation",
        },
        {
          value: EnumOfferCategory.GardenLandscapeDesignOther,
          label: "Other Garden Works",
        },
      ],
    },
    {
      label: "MOVING AND TRANSPORTATION",
      options: [
        { value: EnumOfferCategory.StuffMoving, label: "Stuff Moving" },
        { value: EnumOfferCategory.PeopleTransport, label: "People Transport" },
        {
          value: EnumOfferCategory.MovingTransportationOther,
          label: "Other Moving and Transportation Services",
        },
      ],
    },
    {
      label: "PET CARE",
      options: [
        { value: EnumOfferCategory.DogCare, label: "Dog Care" },
        { value: EnumOfferCategory.CatCare, label: "Cat Care" },
        { value: EnumOfferCategory.AnimalCareOther, label: "Other Pets Care" },
      ],
    },
    {
      label: "BEAUTY",
      options: [
        { value: EnumOfferCategory.Makeup, label: "Makeup" },
        {
          value: EnumOfferCategory.WomensHairdresser,
          label: "Women's Hairdresser",
        },
        {
          value: EnumOfferCategory.MensHairdresser,
          label: "Men's Hairdresser",
        },
        {
          value: EnumOfferCategory.ManicurePedicure,
          label: "Manicure and Pedicure",
        },
        {
          value: EnumOfferCategory.BeautyOther,
          label: "Other Beauty Services",
        },
      ],
    },
    {
      label: "TUTORING",
      options: [{ value: EnumOfferCategory.Tutoring, label: "Tutoring" }],
    },
    {
      label: "OTHER",
      options: [{ value: EnumOfferCategory.Other, label: "Other Services" }],
    },
  ],
};

export const CategoriesResourcesDE: CategoriesResourcesInterface = {
  categories: [
    {
      label: "HAUSHALTS- UND BÜROHILFE",
      options: [
        {
          value: EnumOfferCategory.HouseCleaning,
          label: "Haus- und Wohnungsreinigung",
        },
        { value: EnumOfferCategory.OfficeCleaning, label: "Büroreinigung" },
        { value: EnumOfferCategory.Ironing, label: "Bügeln" },
        {
          value: EnumOfferCategory.HouseOfficeHelpOther,
          label: "Sonstige Haus- und Bürohilfe",
        },
      ],
    },
    {
      label: "HAUSHALTSARBEITEN",
      options: [
        {
          value: EnumOfferCategory.Painting,
          label: "Malerei- und Wandarbeiten",
        },
        {
          value: EnumOfferCategory.ElectricalService,
          label: "Elektrische Dienstleistungen",
        },
        {
          value: EnumOfferCategory.ElectricalAppliances,
          label: "Elektrogerätereparatur",
        },
        {
          value: EnumOfferCategory.FittingAssemblingDissembling,
          label: "Montage – Demontage – Zusammenbau",
        },
        {
          value: EnumOfferCategory.BathroomSanitary,
          label: "Bad- und Sanitärarbeiten",
        },
        { value: EnumOfferCategory.Plumbing, label: "Klempnerarbeiten" },
        { value: EnumOfferCategory.Tiling, label: "Bodenbearbeitung" },
        { value: EnumOfferCategory.FlooringWork, label: "Bodenbelagsarbeiten" },
        {
          value: EnumOfferCategory.HouseWorksOther,
          label: "Sonstige Hausarbeiten",
        },
      ],
    },
    {
      label: "GARTEN- UND LANDSCHAFTSGESTALTUNG",
      options: [
        { value: EnumOfferCategory.Gardening, label: "Gartenarbeit" },
        {
          value: EnumOfferCategory.FenceConstruction,
          label: "Zaunbau",
        },
        {
          value: EnumOfferCategory.GardenRenovation,
          label: "Gartensanierung",
        },
        {
          value: EnumOfferCategory.GardenLandscapeDesignOther,
          label: "Weitere Gartenarbeiten",
        },
      ],
    },
    {
      label: "UMZUG UND TRANSPORT",
      options: [
        { value: EnumOfferCategory.StuffMoving, label: "Umzug" },
        {
          value: EnumOfferCategory.PeopleTransport,
          label: "Personentransport",
        },
        {
          value: EnumOfferCategory.MovingTransportationOther,
          label: "Weitere Umzugs- und Transportdienste",
        },
      ],
    },
    {
      label: "HAUSTIERBETREUUNG",
      options: [
        { value: EnumOfferCategory.DogCare, label: "Hundepflege" },
        { value: EnumOfferCategory.CatCare, label: "Katzenpflege" },
        {
          value: EnumOfferCategory.AnimalCareOther,
          label: "Sonstige Haustierpflege",
        },
      ],
    },
    {
      label: "SCHÖNHEIT",
      options: [
        { value: EnumOfferCategory.Makeup, label: "Makeup" },
        {
          value: EnumOfferCategory.WomensHairdresser,
          label: "Damenfriseur",
        },
        {
          value: EnumOfferCategory.MensHairdresser,
          label: "Herrenfriseur",
        },
        { value: EnumOfferCategory.Makeup, label: "Schminke" },
        {
          value: EnumOfferCategory.ManicurePedicure,
          label: "Maniküre und Pediküre",
        },
        {
          value: EnumOfferCategory.BeautyOther,
          label: "Weitere Schönheitsdienstleistungen",
        },
      ],
    },
    {
      label: "NACHHILFE",
      options: [{ value: EnumOfferCategory.Tutoring, label: "Nachhilfe" }],
    },
    {
      label: "SONSTIGES",
      options: [
        { value: EnumOfferCategory.Other, label: "Sonstige Dienstleistungen" },
      ],
    },
  ],
};

// First, define the interface for the options within each category
interface CategoryOption {
  value: EnumOfferCategory;
  label: string;
}

// Then, define the interface for each category, which includes a label and an array of options
export interface ServiceCategory {
  label: string;
  options: CategoryOption[];
}

export interface CategoriesResourcesInterface {
  categories: ServiceCategory[];
}
